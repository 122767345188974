.ck-editor__editable {
  min-height: 200px !important;
}

.media-style {
  width: 250px;
  border-radius: 4%;
  height: 200px;
  background: #404040;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.media-title-value {
  margin-top: 10px;
}